
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { isValidNumber, stringifySafe } from '@/utils/general'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import { GForm } from '@/components/forms/GForm'
  import { BankData } from '@/entities/persons'
  import GCostField from '@/components/core/input/GCostField.vue'
  import dayjs from 'dayjs'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { DailyAccountBalance } from '@/entities/finance'

@Component({
  components: {
    RowData,
    GCostField,
    LinkedPerson,
    GDatePicker,
    GFiles,
    BaseForm,
  },
})
  export default class DailyAccountBalanceForm extends GForm {
  title = ''
  dailyAccountBalance: DailyAccountBalance = null
  accounts: BankData[] = []
  showDetail = false
  fileInfo = null
  displayCredit = false
  idProcess = null
  metadata = null
  existDailyAccount = []

  formData = {
    account: null,
    balance: null, // Saldo contable
    availableBalance: null, // Saldo disponible
    creditLimit: null, // Cupo utilizado línea de crédito
    backup: [],
  }

  panels = [0]

  fields = {
    backup: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
  }

  async mounted () {
    await this.setMetadata()

    const { id, uid, title, metadata } = this

    if (isValidNumber(id) || isValidNumber(uid)) {
      const dailyAccountBalanceId = id || uid

      await this.getDailyAccountBalanceInfo(dailyAccountBalanceId)
    } else {
      this.existDailyAccount = await this.fetchData({
        query: { name: 'find', model: 'DailyAccountBalance' },
        filter: {
          date: { _eq: dayjs().format('MM-DD-YYYY') },
        },
        force: true,
      })

      this.accounts = this.accounts?.filter(account => !this.existDailyAccount?.some(dailyAccount => dailyAccount.bankAccount.id === account.id))
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(this.dailyAccountBalance?.id))
    }

    this.displayDetail()
  }

  async setMetadata () {
    const { metadata } = this.getForm('DailyAccountBalance', 'treasurer_account_balance')
    const { form } = metadata as Form
    this.title = form.title
    this.metadata = metadata

    this.accounts = await this.fetchData({
      query: { name: 'find', model: 'BankData' },
      filter: {
        holder: { type: { name: { _eq: 'system' } } },
      },
      force: true,
    })

    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'daily_account_balance' } },
    }))[0]

    this.idProcess = process.id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'daily_account_balance' } } },
    })
    this.fileInfo = fileInfo

    this.setProperties(fileInfo, 'backup')
  }

  async getDailyAccountBalanceInfo (id) {
    const dailyAccountBalance = await this.fetchData({
      query: { name: 'fetch', model: 'DailyAccountBalance', params: { id } },
      force: true,
    })

    this.dailyAccountBalance = dailyAccountBalance

    this.formData.account = this.accounts.find(account => account.id === dailyAccountBalance.bankAccount.id)
    this.formData.balance = dailyAccountBalance.accountBalance
    this.formData.availableBalance = dailyAccountBalance.availableBalance
    this.formData.creditLimit = dailyAccountBalance.usedCreditLine
    this.formData.backup = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: this.idProcess } } } },
          { parameter: { name: { _eq: 'balance_backup' } } }],
      },
      force: true,
    })
  }

  setProperties (fileInfo, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo[0]
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  cancelExpense () {
    const { formData: { backup } } = this

    const files = [...backup]

    this.close(files)
  }

  removePersonBackup () {
    const { backup } = this
    if (backup) {
      backup.beneficiary = null
      this.setBackup(backup)
    }
  }

  displayDetail () {
    const { dailyAccountBalance, metadata } = this

    this.metadata = {
      data: dailyAccountBalance,
      metadata,
    }

    this.showDetail = Boolean(dailyAccountBalance?.id)
  }

  get messageError () {
    const { formData } = this

    return Number(formData.availableBalance) > Number(formData.balance) ? 'El saldo disponible no puede ser mayor al saldo contable' : ''
  }

  async send () {
    if (!this.$refs.form.validate() || this.messageError?.length) {
      return
    }

    this.loadingForm = true
    const { dailyAccountBalance } = this

    if (dailyAccountBalance?.id && !dailyAccountBalance?.dailyAccountIsCurrentDay) {
      this.loadingForm = false
      await this.close()
      return
    }

    const { balance, availableBalance, creditLimit, backup, account } = this.formData

    const daily = (await this.fetchData({
      query: { name: 'find', model: 'DailyAccountBalance', order: { id: 'desc' } },
      filter: {
        _and: [
          { id_bank_data: { _eq: account.id } },
          { id: { _neq: dailyAccountBalance?.id } },
        ],
      },
      force: true,
    }))[0]

    const variant = daily?.accountBalance ? (Number(balance) - Number(daily?.accountBalance || 0)) : 0
    const creditVariant = Number(creditLimit) - Number(daily?.usedCreditLine || 0)

    const fields = {
      id: dailyAccountBalance?.id,
      id_bank_data: account?.id,
      account_balance: balance,
      available_balance: availableBalance,
      used_credit_line: isValidNumber(creditLimit) ? creditLimit : 0,
      credit_amount: Number(account?.metadata?.creditAmount) || 0,
      daily_variation: variant,
      credit_variation: creditVariant,
      previous_registration_date: daily?.date,
      date: dayjs().format('MM/DD/YYYY'),
    }

    if (dailyAccountBalance?.id) {
      delete fields.id_bank_data
      delete fields.date
    }
    const newDailyAccountBalance = await this.pushData({
      model: 'DailyAccountBalance',
      fields,
    })

    if (backup?.length) {
      await this.handleFileType(backup, { properties: this.fields.backup }, this.idProcess, newDailyAccountBalance.id, this.fileInfo)
    }

    this.loadingForm = false
    await this.close()
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get isDailyAccountNotCurrentDay () {
    const { dailyAccountBalance } = this

    if (!dailyAccountBalance?.id) {
      return false
    }

    return !dailyAccountBalance?.dailyAccountIsCurrentDay
  }

  get displayBanks () {
    const { dailyAccountBalance } = this

    return Boolean(!dailyAccountBalance?.accountBalance)
  }

  @Watch('formData.account', { immediate: true, deep: true })
  onAccountChange (value) {
    this.displayCredit = value?.isCreditLine
  }
  }
